<template>
  <v-card elevation="0" class="pa-0 price-card">
    <v-container
      class="pa-0"
      :class="zebraStriping % 2 ? 'card-even-style' : 'card-odd-style'"
    >
      <v-row no-gutters>
        <v-col cols="12" class="price-card-item">
          <div
            v-if="configs"
            class="d-flex py-2 px-3 price-card-border cardCBox justify-space-between"
          >
            <span>{{ combinedProductInfo(configs) }}</span>
            <span style="white-space: nowrap"
              >數量：{{ getProductInfo(configs) }}</span
            >
          </div>
        </v-col>
        <v-col cols="12" class="price-card-item">
          <div
            v-if="productSpec && productSpec.label"
            class="d-flex py-2 px-3 price-card-border cardCBox justify-space-between"
          >
            <span>{{ productSpec.label }}</span>
          </div>
        </v-col>
        <v-col cols="12" class="price-card-item">
          <div
            v-if="productSku && productSku.label"
            class="d-flex py-2 px-3 price-card-border cardCBox justify-space-between"
          >
            <span>貨號：{{ productSku.label }}</span>
          </div>
        </v-col>
        <v-col cols="12" class="d-flex">
          <div
            style="width: 100%"
            class="py-2 px-3 price-card-border cardCBox d-sm-none"
            elevation="0"
            tile
          >
            <div class="d-flex justify-space-between align-center mb-2">
              <div class="d-flex justify-start align-center">
                <p class="mb-0 label-t">{{ shippingCount.label }}：</p>
                <div class="d-flex align-center cardInput">
                  <template v-if="shippingCount.disabled">
                    <div class="ml-auto">{{ value[shippingCount.id] }}</div>
                    <input
                      class="mx-2"
                      type="number"
                      hidden
                      :name="shippingCount.name"
                      :value="value[shippingCount.id]"
                    />
                  </template>
                  <number-input
                    v-else
                    class="mx-2"
                    :max="shippingCount.max >= 0 ? shippingCount.max : -1"
                    v-model="data[shippingCount.id]"
                    :fixed="shippingCount.fixed"
                    :name="shippingCount.name"
                    :rule="shippingCount.rule"
                    :control="shippingCount.control || false"
                    @change="handleChange($event, shippingCount)"
                  ></number-input>
                  <p class="mb-0">{{ shippingCount.valueSuffix }}</p>
                </div>
              </div>
              <p style="width: 20%" class="mb-0" v-if="unit.valueSuffix">
                X {{ Number(unit.value) }} {{ unit.valueSuffix }}
              </p>
              <span>，</span>
            </div>
            <div class="d-flex justify-space-between align-center mb-2">
              <div class="d-flex align-center justify-start">
                <p class="mb-0 label-t">{{ price.label }}：</p>
                <div class="d-flex justify-center align-center cardInput">
                  <template v-if="price.disabled">
                    <div class="ml-auto">{{ value[price.id] }}</div>
                    <input
                      type="text"
                      hidden
                      :name="price.name"
                      :value="value[price.id]"
                    />
                  </template>
                  <number-input
                    v-else
                    class="mx-2"
                    :max="price.max >= 0 ? price.max : -1"
                    v-model="data[price.id]"
                    :fixed="price.fixed"
                    :name="price.name"
                    :rule="price.rule"
                    :control="price.control || false"
                    @change="handleChange($event, price)"
                  ></number-input>
                  <p class="mb-0">{{ price.valueSuffix }}</p>
                </div>
              </div>
            </div>
            <div class="d-flex justify-end align-center">
              <div class="py-2">{{ total.label }}：</div>
              <div style="white-space: nowrap" class="py-2 d-flex justify-end">
                {{ handlePrice(total, total.value) }} {{ total.valueSuffix }}
              </div>
            </div>
          </div>
          <div
            style="width: 100%"
            class="py-2 px-3 price-card-border cardCBox d-none d-sm-block"
            elevation="0"
            tile
          >
            <div class="d-flex justify-space-between align-center">
              <p class="mb-0">{{ shippingCount.label }}：</p>
              <div class="d-flex align-center cardInput">
                <template v-if="shippingCount.disabled">
                  <div class="ml-auto">{{ value[shippingCount.id] }}</div>
                  <input
                    type="text"
                    hidden
                    :name="shippingCount.name"
                    :value="value[shippingCount.id]"
                  />
                </template>
                <number-input
                  v-else
                  class="mx-2"
                  :max="shippingCount.max >= 0 ? shippingCount.max : -1"
                  v-model="data[shippingCount.id]"
                  :fixed="shippingCount.fixed"
                  :name="shippingCount.name"
                  :rule="shippingCount.rule"
                  :control="shippingCount.control || false"
                  @change="handleChange($event, shippingCount)"
                ></number-input>
                <p class="mb-0">{{ shippingCount.valueSuffix }}</p>
              </div>
              <p class="mb-0" v-if="unit.valueSuffix">
                X {{ Number(unit.value) }} {{ unit.valueSuffix }}
              </p>
              <span>，</span>
              <p class="mb-0">{{ price.label }}：</p>
              <div class="d-flex align-center cardInput">
                <template v-if="price.disabled">
                  <div class="ml-auto">{{ value[price.id] }}</div>
                  <input
                    type="text"
                    hidden
                    :name="price.name"
                    :value="value[price.id]"
                  />
                </template>
                <number-input
                  v-else
                  class="mx-2"
                  :max="price.max >= 0 ? price.max : -1"
                  v-model="data[price.id]"
                  :fixed="price.fixed"
                  :name="price.name"
                  :rule="price.rule"
                  :control="price.control || false"
                  @change="handleChange($event, price)"
                ></number-input>
                <p class="mb-0">{{ price.valueSuffix }}</p>
              </div>
            </div>
            <div class="d-flex justify-end align-center">
              <div class="py-2" elevation="0" tile>{{ total.label }}：</div>
              <div class="py-2" elevation="0" tile>
                {{ handlePrice(total, total.value) }} {{ total.valueSuffix }}
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import numberInput from "@/modules/base/components/numberInput.vue";
import favoriteBtn from "@/components/buttons/favoriteBtn";

export default {
  components: {
    numberInput,
    // favoriteBtn
  },
  props: {
    value: {
      type: Object,
    },
    configs: {
      type: Array,
    },
    zebraStriping: {
      type: Number,
    },
  },
  data: () => ({
    data: null,
  }),
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.data = this.$eagleLodash.cloneDeep(this.value);
      },
    },
    data: {
      deep: true,
      handler() {
        if (this.$eagleLodash.isEqual(this.value, this.data)) return;
        this.$emit("input", this.data);
      },
    },
  },
  computed: {
    shippingCount() {
      return this.configs.find((item) => item.id === "shipping_count");
    },
    price() {
      return this.configs.find((item) => item.id === "price");
    },
    unit() {
      return this.configs.find((item) => item.id === "unit");
    },
    total() {
      return this.configs.find((item) => item.id === "total");
    },
    productSpec() {
      return this.configs.find((item) => item.id === "product_spec");
    },
    productSku() {
      return this.configs.find((item) => item.id === "product_sku");
    },
  },
  methods: {
    selectionText(options, targetValue) {
      const target = options.find((option) => option.value == targetValue);
      if (!target) return null;
      return target.text;
    },
    itemTypeClass(config) {
      if (!config.barType) return "";
      if (config.barType == "gray") return "price-card-item--gray";
      return "price-card-item--primary";
    },
    numberInputChange(value) {
      this.$emit("numberInputChange", value);
    },
    handleChange(value, config) {
      typeof config.onChange == "function" && config.onChange(value);
    },
    handlePrice(config, price) {
      return this.$fixedPrice(price, config.fixed);
    },
    combinedProductInfo(config) {
      const productName = config.find((item) => item.id === "product_name")
        .label;
      const unitValue = config.find((item) => item.id === "unit").valueText;
      return `${productName}(${unitValue})`;
    },
    getProductInfo(config) {
      const orderCountValue = config.find((item) => item.id === "order_count")
        .value;
      const orderCountValueSuffix = config.find(
        (item) => item.id === "order_count"
      ).valueSuffix;

      return `${orderCountValue} ${orderCountValueSuffix}`;
    },
  },
};
</script>

<style lang="sass">
.price-card
  background: #F6F6F8 !important
  border-radius: 5px !important

  &lineT
    border-top: 1px solid #ffffff

  &lineB
    border-bottom: 1px solid #ffffff

  &lineL
    border-left: 1px solid #ffffff

  &lineR
    border-right: 1px solid #ffffff

  &-border
    border: 1px solid #ffffff
    height: 100%
    align-items: center

  .cardInput
    max-width: 230px
    flex: 1 !important

.linerp-layout
  .price-card-item
    border-radius: 0 0 5px 5px

    &--primary
      color: #ffffff
      background: #009775

    &--gray
      color: #ffffff
      background: #484a4b

.provider-store-layout
  .price-card-item
    border-radius: 0 0 5px 5px

    &--primary
      color: #ffffff
      background: #006DE0

    &--gray
      color: #ffffff
      background: #484a4b

.card-top
  border-radius: 5px 5px 0 0

.card-bottom
  border-radius: 0 0 5px 5px

.card-odd-style
    background-color: #DFEDE9
    color: #11342D

.card-even-style
    background-color: #E5E4E4

@media (max-width: 960px)
  .price-card
    .label-t
      width: 85px
    .cardInput
      max-width: 150px
@media (max-width: 375px)
  .price-card
    .label-t
      width: 70px
    .cardInput
      max-width: 120px
    p
      font-size: 14px
</style>
